import React from 'react'

import StepSequence from 'common/components/job_wizard/StepSequence'
import JobWizardEventEmitter from 'common/components/job_wizard/JobWizardEventEmitter'
import data, { jobData } from 'common/components/job_wizard/data/data'
import JobUpgrades from 'common/components/job_wizard/steps/JobUpgrades'
import JobPostingForm from 'common/components/job_wizard/JobPostingForm'
import UpdateJob from 'common/components/job_wizard/steps/UpdateJob'
import Step from 'common/components/job_wizard/Step'
import CloseModalWarning from 'common/components/job_wizard/CloseModalWarning'
import ProgressBar from 'common/components/ProgressBar'
import postingProgressStore from 'common/components/job_wizard/data/posting_progress/store'
import jobStore from 'common/components/job_wizard/data/store'
import utils from 'common/components/job_wizard/utils'
import coreUtils from 'common/src/utils/coreUtils'
import Details from 'common/components/job_wizard/steps/Details'
import EmailAddress from 'common/components/job_wizard/steps/EmailAddress'
import PrePostJobCheck from 'common/components/job_wizard/steps/PrePostJobCheck'
import actions from 'common/components/job_wizard/data/actions'

StepSequence.init()

const Wizard = React.createClass({

  getInitialState: function() {
    return {
      job: data,
      currentStep: StepSequence.getSteps()[0],
      loading: false,
      stepsWithSignUpBanner: [
        Details,
        EmailAddress
      ]
    }
  },

  componentDidMount: function() {
    let $jobModal = $('#job-modal')

    if (this.props.id) {
      utils.initJob(this.props)
    }

    let browserCloseListener = () => {
      window.addEventListener('beforeunload', this.handleBrowserClose) //beforeunload detects tab/browser close
    }

    function handleModalExit() {
      if (data.id !== null) {
        $jobModal.find('.close-modal-warning').velocity('slideUp').end()
                 .find('.close').rmHidden()

        if(data.additionalQuestion) {
          setTimeout(() => {
            redirectPage();
          }, 500)
          return
        }

        JobWizardEventEmitter.emit('job:wizard:advanceToStep', UpdateJob, 'exit')
        return;
      }

      postingProgressStore.updateTracking({ exitType: 'modal_exit' })
      postingProgressStore.createPostingProgress()
      this.setState({job: jobData.resetData()})
      this.setState({currentStep: StepSequence.getSteps()[0]})
      $jobModal.modal('hide')
      $jobModal.find('.modal-stay').trigger('click') // hide this warning
      coreUtils.googleAnalytics({ hitType: 'event', eventCategory: 'jobpost', eventAction: 'jobexit' })
      window.removeEventListener('beforeunload', this.handleBrowserClose)
      return false;
    }

    function redirectPage() {
      let jobID = data.id
      let url = ''
      switch (data.referrer.cta) {
        case 'profile': {
          url = `/jobs/${jobID}/profilejob?successful_job=true`
        }
        case 'business_directory_card': {
          url = `/jobs/${jobID}/bcardjob?successful_job=true`
        }
        default: {
          url = `/jobs/${jobID}/posted?successful_job=true`
        }
      }
      document.location.href = url
    }


    $jobModal.on('shown.bs.modal', function () {
      $('body')
        .addClass('modal-open')
        .css('overflow','hidden')
        .css('position','fixed')
        .css('width','100%');
    })
    .on('hide.bs.modal', function () {
      $('body')
        .css('overflow','auto')
        .css('position','inherit')
        .css('width','auto');
    })
    .on('hidden.bs.modal', function() {
      $('body').removeClass('modal-open')
      actions.updateOmniAuthDetails( { 'account_from_omni_auth' : false } )
    })

    coreUtils.scrollModalTo = function(selector) {
      var position = $jobModal.find(selector).position()
      $jobModal.animate({scrollTop: position.top}, 'slow');
    }

    postingProgressStore.syncStoredPostingProgress()

    // listeners
    JobWizardEventEmitter.addListener('job:wizard:advanceToStep', (step, eventArgs) => { this.advanceStep(step, eventArgs) })
    JobWizardEventEmitter.addListener('job:wizard:advanceToNextStep', this.advanceStep)
    JobWizardEventEmitter.addListener('job:wizard:retreatToPreviousStep', this.retreatStep)
    jobStore.addChangeListener(this.jobChanged)

    $jobModal.bootstrapValidator({ /* */ })

    $jobModal
      .on('show.bs.modal', (e) => {
        this.initialiseTracking()
        this.initialiseJobData(e)
        browserCloseListener()
      })
      .find('#job_form')
        .on('submit', function(e) {
          e.preventDefault()
          return false;
        })
        .end()
      .find('.modal-exit')
        .on('click', handleModalExit.bind(this))
        .end()
      .find('.modal-stay')
        .on('click', function() {
          $jobModal
            .find('.close-modal-warning')
              .velocity('slideUp')
            .end()
            .find('.close')
              .rmHidden()
          return false;
        })

    coreUtils.appMsg('job-form-loaded') // needed for mobile apps

    if (this.props.pending) {
      let step = this.determineStepForPendingJob()
      JobWizardEventEmitter.emit('job:wizard:advanceToStep', step);
      $jobModal.modal({keyboard: false, backdrop: 'static'});
    }
  },

  determineStepForPendingJob: function() {
    if (this.props.showSuppressants) {
      return ContactPreference
    } else {
      return MoreDetails
    }
  },

  componentWillUnmount: function() {
    JobWizardEventEmitter.removeEventListener('job:wizard:advanceToStep')
    JobWizardEventEmitter.removeListener('job:wizard:advanceToNextStep', this.advanceStep)
    jobStore.removeChangeListener(this.jobChanged)
    window.removeEventListener('beforeunload', this.handleBrowserClose)
  },

  stepHistory: [ StepSequence.getSteps()[0] ],

  initialiseTracking: function () {
    if (data.id === null) {
      postingProgressStore.track = true
    }

    postingProgressStore.updateTracking({
      url: data.referrer.url,
      source: data.referrer.source,
      userId: window.App.currentUserId,
      lastQuestion: 'no interaction',
      categoryId: data.category_id,
      categoryType: 'sub_category' //TODO this needs to change to data.category_type when category code gets updated @JM
    })
  },

  initialiseJobData: function(e) {
    if (typeof e.relatedTarget !== 'undefined') {
      data.referrer.cta = $(e.relatedTarget).data('cta') || e.relatedTarget.cta || 'body'
    }
  },

  handleBrowserClose: function() {
      postingProgressStore.updateTracking({ exitType: 'browser_window_exit' })
      postingProgressStore.storePostingProgress()
      window.removeEventListener('beforeunload')
  },

  jobChanged: function() {
    this.setState({job: data})
  },

  stepIsValid: function() {
    const validator = $('#job-modal').data('bootstrapValidator');
    validator.validate()
    return validator.isValid()
  },

  advanceStep: function(nextStep, eventArgs) {
    const steps = StepSequence.getSteps()

    if (this.stepIsValid() || eventArgs === 'exit') {
      if (!nextStep) {
        let currentStepIndex = steps.indexOf(this.state.currentStep)

        if (currentStepIndex < 0) {
          currentStepIndex = 0
        }

        nextStep = steps[currentStepIndex + 1]
      }

      if (typeof eventArgs !== 'undefined') {
        this.setState(
          {currentStep: nextStep},
          function() {
            return JobWizardEventEmitter.emit(...eventArgs)
          }
        )
      } else {
        this.setState({currentStep: nextStep})
      }

      this.stepHistory.push(nextStep)
    }
  },

  retreatStep: function(step) {
    this.stepHistory.pop() //remove current step from history
    let previousStep = this.stepHistory[this.stepHistory.length - 1]

    this.setState({
      currentStep: previousStep
    })
  },

  handleRequest: function () {
    this.setState({
      loading: true
    })
  },

  handleRequestCompletion: function () {
    this.setState({
      loading: false
    })
  },

  renderStepOrSpinner: function() {
    return (
      <div>
        <div className={`${this.state.loading ? '' : 'hidden'}`}>
          <div className="modal-body job-form-body">
            <div className="spinner-icon text-center"/>
          </div>
        </div>
        <div className={`${this.state.loading ? 'hidden' : ''}`}>
          <Step
            currentStep={this.state.currentStep}
            onStepProgression={this.advanceStep}
            onStepRetreat={this.retreatStep}
            onRequest={this.handleRequest}
            onRequestCompletion={this.handleRequestCompletion}
          />
        </div>
      </div>
    )
  },

  render: function() {
    var component;

    if (!coreUtils.mobileCheck) {
      if(this.state.currentStep === JobUpgrades) {
      component = <JobUpgrades.component onStepRetreat={this.retreatStep}/>
      }
      else if(this.state.currentStep.props && this.state.currentStep.props.isBasicStep) {
        component = this.state.currentStep
      }
      else if(this.state.currentStep === EmailAddress) {
        if(coreUtils.userLoggedIn()) {
          component = <PrePostJobCheck.component/>
        }
        else {
          component = <JobPostingForm stepComponent={this.renderStepOrSpinner()}/>
        }
      }
      else {
        component = <JobPostingForm stepComponent={this.renderStepOrSpinner()}/>
      }
    }

    return (
      <div className="modal-dialog" role="document">
        <div className="modal-content radius-16">
          <CloseModalWarning hideNotPostedWarningMessage={component.props.hideNotPostedWarningMessage}/>
          <ProgressBar steps={StepSequence.getSteps()} currentStep={this.state.currentStep} />
          <div className="modal-component radius-16-bottom">
            {component}
          </div>
        </div>
      </div>
    )
  }
})

export default Wizard
