import React from 'react'
import data from 'common/components/job_wizard/data/data'
import coreUtils from 'common/src/utils/coreUtils'

import store from 'common/components/job_wizard/data/posting_progress/store'

import API from 'common/src/api'

import Cookies from 'js-cookie'
import { Callbacks } from 'jquery'

const PostJob = {
  displayName: 'PostJob',

  component: React.createClass({
    componentDidMount: function() {
      let job = data;
      const $modalFooter = $('#job-modal').find('.modal-footer')

      $modalFooter.hide()

      var subcats = []
      for (var j = 0; j < job.categories.length; j++) {
        subcats[j] = { type: 'subcategories',
                       id: job.categories[j].id }
      }

      var jobCreateData = {
        data: {
          type: 'jobs',
          attributes: {
            description: job.description,
            starting:    job.schedule === '' ? 'flexible' : job.schedule.replace(/_/g, ' '),
            budget:      job.minBudget,
            min_budget:  job.minBudget,
            max_budget:  job.maxBudget,
            can_call:    job.canCall,
            schedule:    job.schedule === '' ? 'flexible' : job.schedule,
            preferred_outcome: job.preferredOutcome,
            interested_in_best_pro: job.interestedInBestPro,
            contact_preference: job.contactPreference,
            disable_processing: true
          },
          relationships: {
            suburb: {
              data: {
                type: 'suburbs',
                id: job.suburb_id
              }
            },
            'job-subcategories': {
              data: subcats
            },
            'exclusive-to-business': {
              data: {
                type: 'businesses',
                id: data.exclusiveFor
              }
            }
          }
        },
        'included': [
          {
            'type': 'alliance-partners',
            'attributes': { 'name': job.originName }
          },
          {
            'type': 'job-referrers',
            'attributes': data.referrer
          },
          {
            'type': 'tracking-data',
            'attributes': {
              'creative':     decodeURI(Cookies.get('creative')),
              'keyword':      decodeURI(Cookies.get('keyword')),
              'matchtype':    decodeURI(Cookies.get('matchtype')),
              'adposition':   decodeURI(Cookies.get('adposition')),
              'campaignid':   decodeURI(Cookies.get('campaignid')),
              'adgroupid':    decodeURI(Cookies.get('adgroupid')),
              'geo':          decodeURI(Cookies.get('geo')),
              'utm_campaign': decodeURI(Cookies.get('utm_campaign')),
              'utm_source':   decodeURI(Cookies.get('utm_source')),
              'utm_medium':   decodeURI(Cookies.get('utm_medium')),
              'utm_content':  decodeURI(Cookies.get('utm_content')),
              'gclid':        decodeURI(Cookies.get('gclid'))
            }
          }
        ]
      }

      $.ajax(API.V2.jobCreate(jobCreateData))
        .done(function(createdJson) {
          store.updateTracking({ jobId: createdJson.data.id })
          data.id = createdJson.data.id
          for (var k = 0; k < (createdJson.included || []).length; k++) {
            if (createdJson.included[k].type === 'credit-cards') {
              data.with_cc   = true
              data.cc_number = createdJson.included[k].attributes.number
            }
          }
          coreUtils.appMsg({ id: createdJson.data.id }) // used by mobile app for controlling the flow

          window.dataLayer = window.dataLayer || []
          window.dataLayer.push({ event: 'custom.event.submitJob' });
          coreUtils.googleAnalytics({hitType: 'event', eventCategory: 'jobpost', eventAction: 'jobpostpending'})

          if(job.attachments.length) {
            uploadFilesToJob(createdJson.data.id)
          } else {
            redirectToJobPage()
          }
        })
        .fail(function() {
          document.location.href = '/500'
        })

      function uploadFilesToJob(jobId) {
        let files = job.attachments
        let requests = []

        var fl = files.length;

        for (var i = 0; i < fl; i++) {
          buildRequests(i)
        }

        function buildRequests(i) {
          let formData = new FormData()
          let apiData = JSON.stringify(API.V2.build.attachment({jobId: jobId}))

          formData.append('attachment', apiData)
          formData.delete && formData.delete('file') // doesn't exist on safari mobile
          formData.append('file', files[i])

          let request = API.V2.attachment({data: formData})
          request.beforeSend = () => {
            $('.status-message').text(`Uploading ${files[i].name}...`)
          }
          requests.push(request)
        }

        function processUploads(index) {
          if (index < requests.length) {
            $.ajax(requests[index])
            .always(() => {
              processUploads(index + 1)
            })
            .fail(() => {
              setAttachmentError()
            })
          } else {
            $('.status-message').text("All Done!")
            redirectToJobPage()
          }
        }

        processUploads(0)
      }

      function setAttachmentError(){
        Cookies.set('job-attachment-error', data.id)
      }

      function redirectToJobPage(){
        document.location.href = redirectUrl()

        function redirectUrl() {
          let jobID = data.id

          switch (data.referrer.cta) {
            case 'profile': {
              return `/jobs/${jobID}/profilejob?pending_job=true`
            }
            case 'business_directory_card': {
              return `/jobs/${jobID}/bcardjob?pending_job=true`
            }
            default: {
              return `/jobs/${jobID}/posted?pending_job=true`
            }
          }
          
        }
      }
    },

    render: function() {
      return (
        <div className="modal-body job-form-body radius-16-bottom">
          <h3>
            Saving your job details
          </h3>
          <p>
            <i className="status-message"></i>
            <div className="spinner-icon text-center" style={{marginLeft: '15px'}}/>
          </p>
        </div>
      )
    }
  })
}

export default PostJob
